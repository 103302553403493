import { useState } from "react";

export default function Example() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <div className="bg-[#031c30] min-h-screen relative">
      <img
        src="./bg.jpg"
        alt=""
        className="hidden sm:block absolute inset-0 rotate-90 md:rotate-0 h-full w-full object-fit xl:object-cover"
      />
      <img
        src="./bg2.jpg"
        alt=""
        className=" sm:hidden absolute inset-0  h-full w-full object-fit lg:object-cover"
      />
      <div className="relative isolate overflow-hidden pt-52 2xl:pt-14">
        <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56 flex flex-col items-center">
          <img
            className="h-14 animate-bounce sm:h-24 lg:h-32 w-auto"
            src="/cohering_logo.svg"
            alt=""
          />
          <div className=" sm:mb-8 sm:flex sm:justify-center">
            <div className="relative flex items-center gap-2 rounded-full px-3 py-1 text-xl font-semibold leading-6 text-sky-400 ring-1 ring-sky/10 hover:ring-sky/20">
              <svg
                className="h-1.5 w-1.5 fill-sky-400 animate-ping"
                viewBox="0 0 6 6"
                aria-hidden="true"
              >
                <circle cx={3} cy={3} r={3} />
              </svg>
              AI Platforming
            </div>
          </div>
          <div className="text-center mt-6 sm:mt-0">
            <h1 className="text-2xl font-bold tracking-tight text-white sm:text-4xl">
              Coming Soon!
            </h1>
          </div>
        </div>
      </div>
      <div className="absolute bottom-0 md:mt-0 md:mb-10 text-center w-full  ">
        <p className="text-center text-sm leading-5 text-white px-2 sm:px-56 shadow-lg bg-[#031c30] sm:bg-transparent py-2">
          &copy; Virtuos Digital Limited.{" "}
          <span className="text-sky-400 font-bold">Cohering</span> is a
          trademark of Virtuos. All rights reserved.
        </p>
      </div>
    </div>
  );
}
